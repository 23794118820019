import { useContext } from "react";
import DynamicInput from "../../../uiwrappers/GenericDynamicInput/GenericDynamicInput";
import PrimaryLink from "../../../uiwrappers/PrimaryLink/PrimaryLink";
import AlertDisplay from "../AlertDisplay/AlertDisplay";
import ContactsVisibilityLink from "../ContactsVisibilityLink/ContactsVisibilityLink";
import RecipientList from "../RecipientList/RecipientList";
import { RequestAReviewModalContext } from "../RequestAReviewModal/RequestAReviewModal";

const RequestAReviewModalStickyTop = () => {
  const {
    recipients,
    addNewContactHandler,
    onChangeHandler,
    searchedContact,
    handleFocus,
    handleBlur,
    contactsVisibility,
    isAddAllClicked,
    informativeMessage,
    alert,
    handleClickShowAll,
    subAlert,
    setAlert,
  } = useContext(RequestAReviewModalContext);

  return (
    <div className={`flex flex-col w-full`}>
      <div className="flex flex-row w-full justify-between">
        <div className="flex">Recipients</div>
        <PrimaryLink
          text="Create New Contact"
          size="md"
          onClickFunc={addNewContactHandler}
        />
      </div>
      <div className="flex w-full relative mt-3">
        <DynamicInput
          type="text"
          name="contact-text"
          placeHolder="Name, phone number, or email"
          handleLocalEdits={onChangeHandler}
          value={searchedContact}
          edit={true}
          handleFocus={handleFocus}
          onBlurFunction={handleBlur}
          autoComplete={false}
          icon="search"
          externalErrorMessage={alert}
          setExternalErrorMessage={setAlert}
        />
      </div>

      <RecipientList
        recipients={recipients}
        contactsVisibility={contactsVisibility}
      />
      <ContactsVisibilityLink
        contactsVisibility={contactsVisibility}
        alert={alert}
        handleClickShowAll={handleClickShowAll}
      />

      {isAddAllClicked && (
        <AlertDisplay alertMessage={informativeMessage} subAlert={subAlert} />
      )}
    </div>
  );
};

export default RequestAReviewModalStickyTop;
