import React from "react";
import PrimaryLink from "../../../uiwrappers/PrimaryLink/PrimaryLink";
import { ContactsVisibilityLinkProps } from "./types";

const ContactsVisibilityLink: React.FC<ContactsVisibilityLinkProps> = ({
  contactsVisibility,
  alert,
  handleClickShowAll,
}) => {
  if (!contactsVisibility && !alert) {
    return null;
  }
  return (
    <div className={`justify-start ${!alert && "w-full"} mb-4`}>
      <PrimaryLink
        text={contactsVisibility as string}
        size="md"
        onClickFunc={handleClickShowAll}
      />
    </div>
  );
};

export default ContactsVisibilityLink;
