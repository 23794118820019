import React, { useContext, useState } from "react";
import { DesktopContext } from "../../../../contexts/DesktopContextProvider/DesktopContextProvider";
import editContact from "../../contacts/helpers/editContact";
import {
  formatPhoneNumber,
  validateEmail,
  validatePhoneNumberNoPlusOne,
} from "../../contacts/helpers/formatPhoneNumber";
import saveNewContact from "../../contacts/helpers/saveNewContact";
import DynamicInput from "../../../uiwrappers/GenericDynamicInput/GenericDynamicInput";
import { XCircleIcon } from "@heroicons/react/solid";
import UtilityModal from "../../../common/UtilityModal/UtilityModal";
import { MobileContext } from "../../../../contexts/MobileContextProvider/MobileContextProvider";

interface AddRecipientModalProps {
  addRecipientModalOpen?: any;
  setAddRecipientModalOpen?: any;
  setIsAddingRecipient?: any;
  editRecipientModalOpen?: any;
  setEditRecipientModalOpen?: any;
  recipients?: any;
  setRecipients: any;
  editedRecipient?: any;
  setReviewRequestModalOpen: any;
}

export default function AddRecipientModal({
  addRecipientModalOpen,
  setAddRecipientModalOpen,
  setIsAddingRecipient,
  setEditRecipientModalOpen,
  editRecipientModalOpen,
  editedRecipient,
  recipients,
  setRecipients,
  setReviewRequestModalOpen,
}: AddRecipientModalProps) {
  const blankContact = {
    profile_photo: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    address: "",
    notes: "",
    city: "",
    state: "",
    zipcode: "",
  };

  const [newContactData, setNewContactData] = useState(
    editedRecipient ? editedRecipient : blankContact
  );
  const [error, setError] = useState<string>("");
  const { profileInfo, contacts, setContacts } = useContext(DesktopContext);
  const [noPhoneOrEmailError, setNoPhoneOrEmailError] = useState(false);
  const [contactAlreadyExists, setContactAlreadyExists] = useState(false);
  const { slug } = profileInfo || {};
  const { isMobileNavHeaderOpen, setIsMobileNavHeaderOpen } =
    useContext(MobileContext);

  async function onSaveAndAddHandler() {
    let error = false;
    if (!newContactData.first_name) {
      setError("Required");
      error = true;
    }
    if (
      !newContactData.phone_number &&
      !newContactData.email &&
      !!newContactData.first_name
    ) {
      setNoPhoneOrEmailError(true);
      error = true;
    }

    if (
      newContactData.phone_number &&
      !validatePhoneNumberNoPlusOne(newContactData.phone_number)
    ) {
      error = true;
    }

    if (newContactData.email && !validateEmail(newContactData.email)) {
      error = true;
    }

    if (error === true) return;

    const contactAlreadyExists = contacts?.find((contact) => {
      return (
        contact.first_name === newContactData.first_name &&
        contact.last_name === newContactData.last_name &&
        contact.phone_number === newContactData.phone_number &&
        contact.email === newContactData.email
      );
    });

    if (contactAlreadyExists) {
      setContactAlreadyExists(true);
      return;
    }

    const newContactArray = [newContactData];
    const newContactResponse = await saveNewContact(slug, newContactArray);
    const newContactId = newContactResponse?.contact_list[0]?.id;
    const newContacts = [...contacts, { ...newContactData, id: newContactId }];

    setContacts(newContacts);
    setRecipients([...recipients, { ...newContactData, id: newContactId }]);
    setIsAddingRecipient(false);
    setAddRecipientModalOpen(false);
    setReviewRequestModalOpen(true);
  }

  async function onSaveAndUpdateHandler() {
    !isMobileNavHeaderOpen ? setIsMobileNavHeaderOpen(true) : null;
    let error = false;
    if (!newContactData.first_name) {
      setError("Required");
      error = true;
    }
    if (
      !newContactData.phone_number &&
      !newContactData.email &&
      !!newContactData.first_name
    ) {
      setNoPhoneOrEmailError(true);
      error = true;
    }

    if (
      newContactData.phone_number &&
      !validatePhoneNumberNoPlusOne(newContactData.phone_number)
    ) {
      error = true;
    }

    if (newContactData.email && !validateEmail(newContactData.email)) {
      error = true;
    }
    if (error === true) return;

    const contactAlreadyExists = contacts?.find((contact) => {
      return (
        contact?.id !== newContactData.id &&
        contact.first_name === newContactData.first_name &&
        contact.last_name === newContactData.last_name &&
        contact.phone_number === newContactData.phone_number &&
        contact.email === newContactData.email
      );
    });

    if (contactAlreadyExists) {
      setContactAlreadyExists(true);
      return;
    }

    await editContact(newContactData);
    const newContacts = [...contacts];
    const indexOfContact = newContacts.findIndex(
      (r) => r.id === newContactData.id
    );
    newContacts[indexOfContact] = newContactData;
    setContacts(newContacts);

    const newRecipients = recipients?.slice();
    const indexOfRecipient = newRecipients.findIndex(
      (r) => r.id === newContactData.id
    );
    newRecipients[indexOfRecipient] = newContactData;
    setRecipients(newRecipients);

    setEditRecipientModalOpen(false);
    setReviewRequestModalOpen(true);
  }

  function onCancelHandler() {
    setReviewRequestModalOpen(true);
    !isMobileNavHeaderOpen ? setIsMobileNavHeaderOpen(true) : null;

    if (editRecipientModalOpen) {
      setEditRecipientModalOpen(false);
    } else {
      setAddRecipientModalOpen(false);
      setIsAddingRecipient(false);
    }
  }

  return (
    <>
      <UtilityModal
        isOpen={
          editRecipientModalOpen
            ? editRecipientModalOpen
            : addRecipientModalOpen
        }
        setIsOpen={() => {
          !isMobileNavHeaderOpen ? setIsMobileNavHeaderOpen(true) : null;
          if (editRecipientModalOpen) {
            setEditRecipientModalOpen(false);
          } else {
            setAddRecipientModalOpen(false);
          }
        }}
        title={editRecipientModalOpen ? "Edit recipient" : "Add Recipient"}
        showCancelButton={true}
        secondaryButtonText="Back"
        showSubmitButton={true}
        submitButtonText={
          editRecipientModalOpen ? "Save and Update" : "Save and Add"
        }
        submitButtonId="save-and-add-new-contact"
        onSubmitHandler={
          editRecipientModalOpen ? onSaveAndUpdateHandler : onSaveAndAddHandler
        }
        onCancelHandler={onCancelHandler}
        showLoader={true}
      >
        {noPhoneOrEmailError ||
          (contactAlreadyExists && (
            <div className="w-full rounded-lg bg-red-50 mt-3">
              <div className="w-full p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <XCircleIcon className="h-6 w-6 text-red-600" />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm-medium text-red-800">
                      {noPhoneOrEmailError && "Phone number or email required"}
                      {contactAlreadyExists &&
                        "Contact already exists in your contacts"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        <form className="flex flex-col space-y-4 sm:mb-4">
          <div className="flex sm:flex-row flex-col w-full sm:space-y-0 space-y-4">
            <div className="flex flex-col sm:w-1/2 w-full sm:mr-4">
              <DynamicInput
                name="first_name"
                value={newContactData.first_name}
                type="text"
                label="First Name"
                // placeHolder="First Name"
                edit={true}
                handleLocalEdits={(e: any) => {
                  setNewContactData({
                    ...newContactData,
                    first_name: e.first_name,
                  });
                  setError("");
                }}
                required={true}
                externalErrorMessage={error}
                setExternalErrorMessage={setError}
              />
            </div>
            <div className="flex sm:w-1/2 w-full">
              <DynamicInput
                name="last_name"
                value={newContactData.last_name}
                type="text"
                label="Last Name"
                // placeHolder="Last Name"
                edit={true}
                handleLocalEdits={(e: any) => {
                  setNewContactData({
                    ...newContactData,
                    last_name: e.last_name,
                  });
                }}
              />
            </div>
          </div>

          <div>
            <DynamicInput
              name="phone_number"
              value={formatPhoneNumber(newContactData.phone_number)}
              type="text"
              label="Phone Number"
              edit={true}
              handleLocalEdits={(e: any) => {
                setNewContactData({
                  ...newContactData,
                  phone_number: e.phone_number,
                });
              }}
              validationOn={true}
            />
          </div>
          <div>
            <DynamicInput
              name="email"
              value={newContactData.email}
              type="text"
              label="Email"
              edit={true}
              handleLocalEdits={(e: any) => {
                setNewContactData({
                  ...newContactData,
                  email: e.email.trim(),
                });
              }}
              validationOn={true}
            />
          </div>
          <div>
            <DynamicInput
              name="address"
              value={newContactData.address}
              type="text"
              label="Street Address"
              edit={true}
              handleLocalEdits={(e: any) => {
                setNewContactData({
                  ...newContactData,
                  address: e.address,
                });
              }}
              validationOn={true}
            />
          </div>
          <div className="flex sm:flex-row flex-col sm:space-y-0 space-y-4">
            <div className="flex sm:w-1/3 w-full sm:mr-4">
              <DynamicInput
                name="city"
                value={newContactData.city}
                type="text"
                label="City"
                edit={true}
                handleLocalEdits={(e: any) => {
                  setNewContactData({
                    ...newContactData,
                    city: e.city,
                  });
                }}
              />
            </div>
            <div className="flex sm:w-1/3 w-full sm:mr-4">
              <DynamicInput
                name="state"
                value={newContactData.state}
                type="text"
                label="State"
                edit={true}
                handleLocalEdits={(e: any) => {
                  setNewContactData({
                    ...newContactData,
                    state: e.state,
                  });
                }}
                isSmall
              />
            </div>
            <div className="flex sm:w-1/3 w-full">
              <DynamicInput
                name="zipcode"
                value={newContactData.zipcode}
                type="text"
                label="Zip Code"
                edit={true}
                handleLocalEdits={(e: any) => {
                  setNewContactData({
                    ...newContactData,
                    zipcode: e.zipcode,
                  });
                }}
                isSmall
              />
            </div>
          </div>
        </form>
      </UtilityModal>
    </>
  );
}
