import { RequestAReviewModalContext } from "../RequestAReviewModal/RequestAReviewModal";
import { useContext } from "react";
import useIsMobile from "../../../../hooks/useIsMobile";
import PrimaryButton from "../../../uiwrappers/PrimaryButton/PrimaryButton";
import SecondaryButton from "../../../uiwrappers/SecondaryButton/SecondaryButton";

const ReviewRequestModalFooterButtons = () => {
  const {
    stage,
    setStage,
    handleSubmit,
    currentSelection,
    isSubmitProcessing,
  } = useContext(RequestAReviewModalContext);
  const isMobile = useIsMobile();

  const selectRequestType = (
    <div
      className={`${
        isMobile ? "w-full " : ""
      }flex flex-row items-center justify-between gap-2`}
    >
      <SecondaryButton
        text="Email"
        size="md extra padding"
        onClickFunc={() => {
          handleSubmit("email");
        }}
        customStyle={`${isMobile ? "w-full" : ""}`}
        disabled={isSubmitProcessing}
      />
      <PrimaryButton
        text="Text"
        size="md extra padding"
        onClickFunc={() => {
          handleSubmit("text");
        }}
        customStyle={`${isMobile ? "w-full" : ""}`}
        disabled={isSubmitProcessing}
      />
    </div>
  );

  return (
    <div className="flex flex-row w-full justify-end p-2">
      {stage === "1" ? (
        <PrimaryButton
          text="Next"
          size="md extra padding"
          onClickFunc={() => {
            setStage("2");
          }}
          customStyle={`${isMobile ? "w-full" : ""}`}
        />
      ) : currentSelection === "send" ? (
        <PrimaryButton
          text="Send"
          size="md extra padding"
          onClickFunc={() => {
            handleSubmit("send");
          }}
          customStyle={`${isMobile ? "w-full" : ""}`}
          disabled={isSubmitProcessing}
        />
      ) : (
        selectRequestType
      )}
    </div>
  );
};

export default ReviewRequestModalFooterButtons;
