import React, { useEffect, useState, useContext } from "react";
import { checkIfAlreadySentRequestReviewWithin6Months } from "../../contacts/ContactsPage/utils";
import { CheckIcon, StarIcon, SearchCircleIcon } from "@heroicons/react/solid";
import StackedList from "../../../common/StackedList/StackedList";
import { IContact } from "../../contacts/Contact/types";
import PrimaryLink from "../../../uiwrappers/PrimaryLink/PrimaryLink";
import { RequestAReviewModalContext } from "../RequestAReviewModal/RequestAReviewModal";

interface ReviewContactTableProps {
  displayedContacts: IContact[];
  contactOnClickHandler: (_contact: IContact | string) => void;
  newContact: string;
  searchedContact: string;
  recipients: IContact[];
}

export default function ReviewContactTable({
  displayedContacts,
  contactOnClickHandler,
  newContact,
  searchedContact,
  recipients = [],
}: ReviewContactTableProps): React.ReactElement {
  const { setRecipients } = useContext(RequestAReviewModalContext);
  const [unsentContacts, setUnsentContacts] = useState<IContact[]>([]);
  const [sentContacts, setSentContacts] = useState<IContact[]>([]);
  const [selectedItems, setSelectedItems] = useState<{
    [key: string]: boolean;
  }>({});
  const noSearchResults = displayedContacts.length === 0;

  function handleAddAllRecipients() {
    setRecipients(unsentContacts);
  }

  useEffect(() => {
    setSentContacts([]);
    setUnsentContacts([]);
    displayedContacts.forEach((contact) => {
      if (
        checkIfAlreadySentRequestReviewWithin6Months(
          new Date(contact?.date_request_review_sent)
        ) ||
        contact?.left_a_review
      ) {
        setSentContacts((prev) => [...prev, contact]);
      } else {
        setUnsentContacts((prev) => [...prev, contact]);
      }
    });
  }, [displayedContacts]);

  useEffect(() => {
    setSelectedItems(() => {
      const initialSelectedItems: { [key: string]: boolean } = {};
      displayedContacts.forEach((contact) => {
        const isRecipient = recipients.some(
          (recipient) => recipient.id === contact.id
        );
        initialSelectedItems[contact.id] = isRecipient ? true : false;
      });
      return initialSelectedItems;
    });
  }, [recipients]);

  function onClickHandler(contact: IContact) {
    contactOnClickHandler(contact);
    setSelectedItems((prevSelectedItems) => ({
      ...prevSelectedItems,
      [contact.id]: !prevSelectedItems[contact.id],
    }));
  }

  function getTipText(contact: IContact) {
    const request_sent = checkIfAlreadySentRequestReviewWithin6Months(
      new Date(contact?.date_request_review_sent)
    );
    if (request_sent) {
      return (
        <div className="flex gap-1 items-center text-gray-700 h-full mx-3">
          <CheckIcon className="w-4 h-4 text-blue-600" />
          <div className="text-center text-gray-700 text-sm-medium leading-none">
            Request Sent
          </div>
        </div>
      );
    } else if (contact?.left_a_review) {
      return (
        <div className="flex gap-1 items-center text-gray-700 h-full mx-3">
          <StarIcon className="w-4 h-4 text-amber-400" />
          <div className="text-center text-gray-700 text-sm-medium  leading-none">
            Left a Review
          </div>
        </div>
      );
    }
  }

  function handleClick() {
    contactOnClickHandler(searchedContact);
  }

  return (
    <div className="relative flex flex-col w-full overflow-y-auto">
      {noSearchResults && (
        <>
          <div className="justify-start items-start pt-4 inline-flex">
            <div
              className="text-blue-600 text-sm font-medium leading-tight cursor-pointer"
              onClick={handleClick}
            >
              Request review from {searchedContact}
            </div>
          </div>
          <div className="h-44 py-10 flex-col justify-center items-center gap-3 inline-flex">
            <SearchCircleIcon className="w-12 h-12 text-gray-400" />
            <div className="self-stretch h-12 flex-col justify-start items-start gap-1 flex">
              <div className="self-stretch text-center text-gray-900 text-base font-bold leading-normal">
                No contacts matching your search
              </div>
              <div className="self-stretch text-center text-gray-500 text-sm font-normal leading-tight">
                Please check spelling or add a recipient using phone number or
                email
              </div>
            </div>
          </div>
        </>
      )}
      {unsentContacts && unsentContacts.length > 0 && (
        <>
          <div className="flex flex-row w-full justify-between pt-2">
            <div className="h-6 justify-between items-center inline-flex py-4">
              Unsent
            </div>
            <PrimaryLink
              text="Select all"
              size="md"
              onClickFunc={handleAddAllRecipients}
              customStyle="justify-end items-end"
            />
          </div>
          <div>
            <StackedList
              data={unsentContacts}
              renderItemAdditionalInfo={getTipText}
              onItemClickHandler={onClickHandler}
              selectedItems={selectedItems}
            />
          </div>
        </>
      )}
      {sentContacts && sentContacts.length > 0 && (
        <>
          <div className="flex flex-row w-full">
            <div className="h-6 justify-between items-center inline-flex py-4">
              Sent
            </div>
          </div>
          <StackedList
            data={sentContacts}
            renderItemAdditionalInfo={getTipText}
          />
        </>
      )}
    </div>
  );
}
