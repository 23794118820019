import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { toast } from "react-toastify";
import { useSWRConfig } from "swr";
import validator from "validator";
import { DesktopContext } from "../../../../contexts/DesktopContextProvider/DesktopContextProvider";
import UtilityModal from "../../../common/UtilityModal/UtilityModal";
import EmptyState from "../../../uiwrappers/EmptyState/EmptyState";
import { IContact } from "../../contacts/Contact/types";
import { useContacts } from "../../../../hooks/useContacts/useContacts";
import PrimaryButton from "../../../uiwrappers/PrimaryButton/PrimaryButton";
import {
  validateEmail,
  validatePhoneNumber,
} from "../../contacts/helpers/formatPhoneNumber";
import AddRecipientModal from "../AddRecipientModal/AddRecipientModal";
import RequestAReviewModalCustomTextArea from "../RequestAReviewModalCustomTextArea/RequestAReviewModalCustomTextArea";
import RequestAReviewModalStickyTop from "../RequestAReviewModalStickyTop/RequestAReviewModalStickyTop";
import ReviewRequestModalFooterButtons from "./ReviewRequestModalFooterButtons";
import ReviewContactTable from "../ReviewContactTable/ReviewContactTable";
import { postReviewRequest } from "../ReviewContainer/utils";
import { SEND_CHANNEL_TYPE } from "./constants";
import { ChannelType, ContactsVisibility } from "./types";
import {
  adjustMessageForMultipleNames,
  channelTypeConfig,
  checkIfAlreadySentRequestReviewWithin6Months,
  determineInformativeMessage,
  determineSubAlert,
  filterAndSortContacts,
  getCounterAndInvalidRecipients,
  getDefaultRequestReviewForLandingPage,
  isContactMatchingSearch,
  parseRecipients,
  replaceMessageWithSingleName,
  updateContactsForReviewSent,
} from "./utils";
import { BLANK_CONTACT } from "../../contacts/ContactsPage/constants";
import useContactInfo from "../../../../hooks/useContactInfo/useContactInfo";
import SecondaryButton from "../../../uiwrappers/SecondaryButton/SecondaryButton";
import fetchAllContacts from "../../contacts/helpers/fetchAllContacts";
import { useRouter } from "next/router";
import useIsMobile from "../../../../hooks/useIsMobile";

interface RequestAReviewModalProps {
  setReviewRequestModalOpen: any;
  reviewRequestModalOpen: any;
  recipients: IContact[];
  setRecipients: Dispatch<SetStateAction<IContact[]>>;
  fromContactsPage?: boolean;
}

export const RequestAReviewModalContext = createContext({} as any);

async function loadContacts(
  setContacts: React.Dispatch<React.SetStateAction<IContact[]>>
) {
  try {
    const contacts = await fetchAllContacts();
    setContacts(contacts);
  } catch (error) {
    toast.error("Failed to retrieve contacts");
    return [];
  }
}

export default function RequestAReviewModal({
  setReviewRequestModalOpen,
  reviewRequestModalOpen,
  recipients,
  setRecipients,
  fromContactsPage,
}: RequestAReviewModalProps) {
  const { profileInfo, requestsCount, setRequestCount, basicInfo } =
    useContext(DesktopContext);

  const isMobile = useIsMobile();
  const { renderCsvModal, setCsvUploadModalOpen } = useContacts();
  const [contacts, setContacts] = useState<IContact[]>([]);
  const { mutateContactInfo } = useContactInfo(profileInfo);
  const { slug } = profileInfo || {};
  const { default_request_review_text } = basicInfo || "";
  const [stage, setStage] = useState("1");
  const [informativeMessage, setInformativeMessage] = useState("");
  const router = useRouter();

  const [counter, setCounter] = useState(0);
  const [invalidRecipients, setInvalidRecipients] = useState<number[]>([]);
  const [alert, setAlert] = useState("");
  const [searchedContact, setSearchedContact] = useState("");
  const [editedRecipient, setEditedRecipient] = useState("");

  const [areValidRecipients, setAreValidRecipients] = useState(true);
  const [displayedContactsInModal, setDisplayedContactsInModal] = useState<
    IContact[]
  >([]);
  const [editRecipientModalOpen, setEditRecipientModalOpen] = useState(false);
  const [localRequestReviewMessage, setLocalRequestReviewMessage] =
    useState("");
  const [isAddingRecipient, setIsAddingRecipient] = useState(false);
  const [newContact, setNewContact] = useState("");
  const [addRecipientModalOpen, setAddRecipientModalOpen] =
    useState(isAddingRecipient);
  const [currentSelection, setCurrentSelection] =
    useState<ChannelType>("neutral");

  const [subAlert, setSubAlert] = useState("");

  const [defaultMessage, setDefaultMessage] = useState("");
  const [stageWhenFocused, setStageWhenFocused] = useState("1");
  const { mutate } = useSWRConfig();

  const [isSubmitProcessing, setIsSubmitProcessing] = useState(false);

  const [contactsVisibility, setContactsVisibility] =
    useState<ContactsVisibility>(ContactsVisibility.NONE);

  const [isAddAllClicked, setIsAddAllClicked] = useState(false);

  useEffect(() => {
    loadContacts(setContacts);
  }, [reviewRequestModalOpen]);

  useEffect(() => {
    if (contacts.length > 0) {
      const newContacts = filterAndSortContacts(
        contacts,
        invalidRecipients,
        recipients
      );
      setDisplayedContactsInModal(newContacts);
    }
  }, [contacts]);

  useEffect(() => {
    const results = getCounterAndInvalidRecipients(contacts, 0);
    setInvalidRecipients(results.invalidRecipientsVar);
    setCounter(results.newCounter);
  }, [recipients]);

  useEffect(() => {
    const mobileNavbar = document.getElementById("mobile-head-navbar");
    if (mobileNavbar) {
      if (reviewRequestModalOpen) {
        mobileNavbar.classList.add("hidden");
      } else {
        mobileNavbar.classList.remove("hidden");
      }
    }
  }, [reviewRequestModalOpen]);

  useEffect(() => {
    if (fromContactsPage || recipients.length > 0) {
      setStage("2");
    }
  }, [reviewRequestModalOpen]);

  useEffect(() => {
    const localStorageMessage = localStorage.getItem("message");
    const localStorageShowAll = localStorage.getItem("showAll");
    if (localStorageMessage && reviewRequestModalOpen) {
      setDefaultMessage(localStorageMessage);
      localStorageShowAll &&
        setContactsVisibility(localStorageShowAll as ContactsVisibility);
      setLocalRequestReviewMessage(localStorageMessage);
      localStorage.removeItem("message");
      localStorage.removeItem("showAll");
    } else {
      const firstName = profileInfo?.first_name;
      const lastName = profileInfo?.last_name;
      const companyName = profileInfo?.company_name;
      const phone = basicInfo?.phone;
      const email = basicInfo?.email;

      const defaultRequestReviewForLandingPage =
        getDefaultRequestReviewForLandingPage(
          default_request_review_text,
          firstName,
          lastName,
          companyName,
          phone,
          email
        );
      const trimmedDefaultMessage = defaultRequestReviewForLandingPage?.trim();
      setDefaultMessage(trimmedDefaultMessage);
      setLocalRequestReviewMessage(trimmedDefaultMessage);
    }
  }, [
    profileInfo,
    basicInfo,
    reviewRequestModalOpen,
    default_request_review_text,
  ]);

  useEffect(() => {
    if (recipients.length === 0) {
      setCurrentSelection("neutral");
      if (defaultMessage) {
        setLocalRequestReviewMessage(defaultMessage);
      }
    }
  }, [recipients]);

  useEffect(() => {
    if (
      !editRecipientModalOpen &&
      !addRecipientModalOpen &&
      recipients.length > 0
    ) {
      setStage("2");
    }
  }, [editRecipientModalOpen, addRecipientModalOpen]);

  useEffect(() => {
    if (alert === "") {
      setSubAlert("");
    }
  }, [alert]);

  useEffect(() => {
    const subAlert = determineSubAlert(counter);
    if (subAlert) {
      setSubAlert(subAlert);
    }

    setInformativeMessage(determineInformativeMessage(counter));
  }, [counter]);

  useEffect(() => {
    const allRecipientsHaveBothPhoneAndEmail = recipients.every(
      (recipient: any) => !!recipient.phone_number && !!recipient.email
    );

    setCurrentSelection(
      allRecipientsHaveBothPhoneAndEmail ? "neutral" : "send"
    );
  }, [recipients]);

  useEffect(() => {
    if (recipients.length === 0) return;

    const firstName = recipients[0]?.first_name;

    if (!firstName) return;

    let updatedMessage;

    if (recipients.length === 1) {
      updatedMessage = replaceMessageWithSingleName(
        localRequestReviewMessage,
        firstName
      );
    } else if (recipients.length > 1) {
      updatedMessage = adjustMessageForMultipleNames(
        localRequestReviewMessage,
        firstName
      );
    }

    // Update message if needed
    if (updatedMessage) {
      setLocalRequestReviewMessage(updatedMessage);
    }
  }, [recipients]);

  useEffect(() => {
    const recipientsElement = document.getElementById("recipients");
    if (recipientsElement) {
      const height = recipientsElement.offsetHeight;
      if (height > 80) {
        setContactsVisibility((prevState) =>
          prevState === ContactsVisibility.HIDE
            ? ContactsVisibility.HIDE
            : ContactsVisibility.SHOW_ALL
        );
      } else {
        setContactsVisibility(ContactsVisibility.NONE);
      }
    }
  }, [recipients]);

  function handleFocus() {
    setStage("1");
    setStageWhenFocused(stage);
  }

  function handleBlur(e: any) {
    if (!e.relatedTarget && stageWhenFocused === "2") {
      setStage(searchedContact === "" ? "2" : "1");
    }
  }

  function onChangeHandler(e: any) {
    const contactInput = e["contact-text"];
    setNewContact(contactInput);
    setSearchedContact(contactInput);
    setAlert("");

    const searchTerm = contactInput.toLowerCase();
    const filteredContacts = contacts.filter(
      (contact) =>
        searchTerm.length === 0 || isContactMatchingSearch(contact, searchTerm)
    );

    setDisplayedContactsInModal(filteredContacts);
  }

  function addNewContactHandler() {
    setReviewRequestModalOpen(false);
    setIsAddingRecipient(true);
    setAddRecipientModalOpen(true);
    localStorage.setItem("showAll", contactsVisibility);
    localStorage.setItem("message", localRequestReviewMessage);
  }

  function contactOnClickHandler(contact: any) {
    setAlert("");
    if (typeof contact === "string") {
      contact = contact.trim();
      if (
        !validator.isMobilePhone(contact, "en-US") &&
        !validator.isEmail(contact)
      ) {
        setAlert("Please enter a valid email or phone number");
        return;
      } else if (validateEmail(contact)) {
        setCurrentSelection("email");
        setRecipients([
          ...recipients,
          {
            ...BLANK_CONTACT,
            email: contact,
            value: contact,
            selected: true,
          } as any,
        ]);
      } else if (validatePhoneNumber(contact)) {
        setCurrentSelection("text");
        setRecipients([
          ...recipients,
          {
            ...BLANK_CONTACT,
            phone_number: contact,
            value: contact,
            selected: true,
          } as any,
        ]);
      }
      if (contacts.length > 0) {
        const newContacts = filterAndSortContacts(contacts, invalidRecipients);
        setDisplayedContactsInModal(newContacts);
      }
    }
    // if there is id in contact, append contact to recipients array
    else {
      if (contact.date_request_review_sent) {
        const bool = checkIfAlreadySentRequestReviewWithin6Months(
          new Date(contact.date_request_review_sent)
        );
        if (bool) {
          setCounter(counter + 1);
          return;
        }
      }
      const contactIndex = recipients.findIndex(
        (recipient) => recipient.id === contact.id
      );

      if (contactIndex !== -1) {
        setRecipients(recipients.filter((_, index) => index !== contactIndex));
      } else {
        setRecipients([...recipients, { ...contact }]);
      }
    }
  }

  function onCancelHandler() {
    setReviewRequestModalOpen(false);
    setCounter(0);
    setInvalidRecipients([]);
    setAlert("");
    setInformativeMessage("");
    setStage("1");

    setTimeout(() => {
      setSearchedContact("");
      setRecipients([]);
      setDisplayedContactsInModal(contacts);
    }, 500);
  }

  function validateRecipientInfo(channelType: ChannelType): boolean {
    if (recipients.length === 0) {
      setAreValidRecipients(true);
      return true;
    }
    const config = channelTypeConfig[channelType];
    const valid = recipients.every(config.validator);

    setAreValidRecipients(valid);

    if (!valid && config.alertMessage) {
      setAlert(config.alertMessage);
    }

    return valid;
  }

  async function sendReviewRequest(
    channelType: string,
    contactIds: number[],
    nonContactRecipients: IContact[]
  ): Promise<void> {
    await postReviewRequest(
      slug,
      channelType,
      contactIds,
      nonContactRecipients,
      localRequestReviewMessage,
      false
    );
    mutate([`/api/review-requests?slug=`, profileInfo?.slug]);
    mutateContactInfo();
    toast.success("Review request sent");
  }

  function resetStateAfterSave(): void {
    setReviewRequestModalOpen(false);
    setAlert("");

    setTimeout(() => {
      setSearchedContact("");
      setRecipients([]);
    }, 500);

    setRequestCount(requestsCount + recipients.length);
    loadContacts(setContacts);
  }

  async function onSaveHandler(channelType: ChannelType): Promise<void> {
    const isValidRecipient = validateRecipientInfo(channelType);

    if (alert || !isValidRecipient || recipients.length === 0) {
      setAlert("Required");
      return;
    }

    if (areValidRecipients || channelType === SEND_CHANNEL_TYPE) {
      const { contactIds, nonContactRecipients } = parseRecipients(recipients);
      const newContacts = updateContactsForReviewSent(contacts, recipients);
      setContacts(newContacts);
      await sendReviewRequest(channelType, contactIds, nonContactRecipients);
      resetStateAfterSave();
      handleClose();
    }
  }

  function handleClickShowAll() {
    const recipientsElement = document.getElementById("recipients");

    if (contactsVisibility === ContactsVisibility.SHOW_ALL) {
      setContactsVisibility(ContactsVisibility.HIDE);
    } else {
      if (recipientsElement) {
        recipientsElement.scrollIntoView({ behavior: "auto" });
      }
      setContactsVisibility(ContactsVisibility.SHOW_ALL);
    }
  }

  async function onSubmitHandler(requestType: ChannelType) {
    setIsSubmitProcessing(true);
    await onSaveHandler(requestType);
    setIsSubmitProcessing(false);
  }

  function handleClickToSettings() {
    handleClose();
    router.push("/settings/reviews");
  }

  function handleSubmit(reviewType: ChannelType) {
    if (stage === "2") {
      onSubmitHandler(reviewType);
    }
  }

  function handleClose() {
    if (reviewRequestModalOpen) {
      setReviewRequestModalOpen(false);

      setSearchedContact("");
      setRecipients([]);
      setContactsVisibility(ContactsVisibility.NONE);
      setCounter(0);
      setInvalidRecipients([]);
      setAlert("");
      setInformativeMessage("");
      setStage("1");
    } else {
      setReviewRequestModalOpen(true);
    }
  }

  const handleLocalMessageOnChange = (e: any) => {
    setLocalRequestReviewMessage(e.target.value);
  };

  const RequestAReviewModalContextValue = {
    recipients,
    setRecipients,
    isSubmitProcessing,
    currentSelection,
    setReviewRequestModalOpen,
    addNewContactHandler,
    onChangeHandler,
    searchedContact,
    handleFocus,
    handleBlur,
    contactsVisibility,
    informativeMessage,
    alert,
    setAlert,
    editRecipientModalOpen,
    stage,
    setStage,
    counter,
    setEditRecipientModalOpen,
    setEditedRecipient,
    onCancelHandler,
    areValidRecipients,
    setAreValidRecipients,
    subAlert,
    localRequestReviewMessage,
    handleClickShowAll,
    handleLocalMessageOnChange,
    isAddAllClicked,
    setCsvUploadModalOpen,
    handleSubmit,
    handleClickToSettings,
  };

  const showEmptyState =
    contacts?.length === 0 && recipients.length === 0 && searchedContact === "";
  const isStageOne = stage === "1" && !showEmptyState;
  const isStageTwo = stage === "2" && !showEmptyState;

  return (
    <RequestAReviewModalContext.Provider
      value={RequestAReviewModalContextValue}
    >
      <UtilityModal
        isOpen={reviewRequestModalOpen}
        setIsOpen={onCancelHandler}
        onCancelHandler={handleClose}
        title="Request a Review"
        showCancelButton={false}
        showSubmitButton={false}
        customButtons={<ReviewRequestModalFooterButtons />}
        width="sm:w-800"
        customOverflow={`${
          isMobile
            ? "overflow-auto"
            : "h-full max-h-screen relativemax-h-screen m-2 overflow-hidden "
        }`}
        footerBorder={true}
        disablePadding={true}
      >
        <div className={`${isMobile ? "p-4" : "max-h-500 overflow-auto"}`}>
          <RequestAReviewModalStickyTop />
          <div
            id="request-review-section"
            className="w-full h-full overflow-auto flex flex-col  items-center justify-start"
          >
            {showEmptyState && (
              <>
                <EmptyState
                  header="You don't have any contacts yet"
                  subheader=""
                  icon="contacts"
                />
                <div className="flex justify-center items-center gap-4 -mt-6">
                  <SecondaryButton
                    onClickFunc={addNewContactHandler}
                    size="xl"
                    text="Add New"
                    iconPlacement="leading"
                    icon="plus"
                  />
                  <PrimaryButton
                    onClickFunc={() => {
                      handleClose();
                      setCsvUploadModalOpen(true);
                    }}
                    size="xl"
                    text="Import"
                    iconPlacement="leading"
                    icon="upload"
                  />
                </div>
              </>
            )}
            {isStageOne && (
              <ReviewContactTable
                displayedContacts={displayedContactsInModal}
                contactOnClickHandler={contactOnClickHandler}
                newContact={newContact}
                searchedContact={searchedContact}
                recipients={recipients}
              />
            )}
            {isStageTwo && <RequestAReviewModalCustomTextArea />}
          </div>
        </div>
      </UtilityModal>

      {renderCsvModal()}

      {addRecipientModalOpen && (
        <AddRecipientModal
          recipients={recipients}
          addRecipientModalOpen={addRecipientModalOpen}
          setAddRecipientModalOpen={setAddRecipientModalOpen}
          setIsAddingRecipient={setIsAddingRecipient}
          setRecipients={setRecipients}
          setReviewRequestModalOpen={setReviewRequestModalOpen}
        />
      )}
      {editRecipientModalOpen && (
        <AddRecipientModal
          editRecipientModalOpen={editRecipientModalOpen}
          setEditRecipientModalOpen={setEditRecipientModalOpen}
          recipients={recipients}
          setRecipients={setRecipients}
          editedRecipient={editedRecipient}
          setReviewRequestModalOpen={setReviewRequestModalOpen}
        />
      )}
    </RequestAReviewModalContext.Provider>
  );
}
