import React, { useCallback, useContext } from "react";
import useIsMobile from "../../../hooks/useIsMobile";
import { RequestAReviewModalContext } from "../../primaryrender/reviews/RequestAReviewModal/RequestAReviewModal";
import { renderToString } from "react-dom/server";
import reactStringReplace from "react-string-replace";
import { cleanHTML } from "./utils";
import { CogIcon } from "@heroicons/react/outline";

interface CustomChipArea {
  CustomChipTextArea: { [key: string]: string };
}

const CustomChip: React.FC<CustomChipArea> = ({ CustomChipTextArea }) => {
  const isMobile = useIsMobile();
  const {
    alert,
    showAll,
    localRequestReviewMessage,
    handleLocalMessageOnChange,
    handleClickToSettings,
  } = useContext(RequestAReviewModalContext);

  const varDiv = useCallback((content: string) => {
    return (
      <div
        className={`inline-flex items-center rounded-full bg-blue-100 text-blue-800 py-0.5 pl-2 pr-2 text-sm-medium mb-1 `}
        contentEditable={false}
      >
        {content}
      </div>
    );
  }, []);

  const messageToHTML = useCallback(
    (currentValue: string): string => {
      let updatedString = currentValue;

      updatedString = reactStringReplace(updatedString, "\n", (match, i) => (
        <br key={i} />
      ));

      Object.keys(CustomChipTextArea).forEach((key) => {
        updatedString = reactStringReplace(updatedString, key, (match, i) =>
          varDiv(CustomChipTextArea[key])
        );
      });

      return renderToString(updatedString);
    },
    [CustomChipTextArea, varDiv]
  );

  const htmlToMessage = useCallback(
    (currentValue: string): string => {
      currentValue = currentValue.replaceAll("\n", "");
      currentValue = currentValue.replace(
        /<br\s+data-reactroot=""\s*\/?>/gi,
        "\n"
      );
      currentValue = cleanHTML(currentValue);

      Object.keys(CustomChipTextArea).forEach((key) => {
        currentValue = currentValue.replaceAll(CustomChipTextArea[key], key);
      });

      return currentValue;
    },
    [CustomChipTextArea]
  );

  function updateSettingsBadgeText() {
    return (
      <div className="flex justify-start items-center gap-1 py-2">
        <div className="text-gray-500 text-sm font-normal font-['Inter'] leading-tight">
          Edit your default message in
        </div>
        <span
          className="flex justify-start items-center gap-1.5 cursor-pointer"
          onClick={handleClickToSettings}
        >
          <CogIcon className="w-5 h-5 text-gray-600" />
          <div className="text-gray-600 text-sm font-medium font-['Inter'] leading-tight">
            Settings {">"} Reviews
          </div>
        </span>
      </div>
    );
  }

  return (
    <>
      <div
        data-testid="inputContainer"
        className="flex justify-center w-full h-full box-content"
      >
        {isMobile ? (
          <div className="w-full h-full flex flex-col">
            <div
              className="bg-white border border-gray-300 rounded-lg w-full text-body-normal text-gray-900 focus:ring-0 resize-none p-4 pt-2 overflow-auto flex-grow"
              contentEditable={true}
              dangerouslySetInnerHTML={{
                __html: messageToHTML(localRequestReviewMessage),
              }}
              onBlur={(e) => {
                handleLocalMessageOnChange({
                  target: {
                    value: htmlToMessage(e.currentTarget.innerHTML),
                  },
                });
              }}
            ></div>
            <div className="mt-2">{updateSettingsBadgeText()}</div>
          </div>
        ) : (
          <div
            className={`w-full h-full mb-2 ${
              !showAll && !alert && "mt-4"
            } flex flex-col`}
          >
            <div className="flex-grow flex flex-col">
              <div
                className="bg-white border border-gray-300 rounded-lg w-full text-body-normal text-gray-900 focus:ring-0 resize-none p-4 pt-2 overflow-auto h-200"
                contentEditable={true}
                dangerouslySetInnerHTML={{
                  __html: messageToHTML(localRequestReviewMessage),
                }}
                onBlur={(e) => {
                  handleLocalMessageOnChange({
                    target: {
                      value: htmlToMessage(e.currentTarget.innerHTML),
                    },
                  });
                }}
              ></div>
              <div className="mt-2">{updateSettingsBadgeText()}</div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CustomChip;
