import React, { useState, useEffect, useRef } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { AsYouType } from "libphonenumber-js";
import validator from "validator";
import GenericTextArea from "../../common/GenericTextArea/GenericTextArea";
import Alert from "../Alert/Alert";
import { InformationCircleIcon, SearchIcon } from "@heroicons/react/outline";
import Fuse from "fuse.js";
import SuggestedEmail from "./SuggestedEmail";
//need to rewrite this with style args
export interface DynamicInputProps {
  /**
   * current state representing the value the input should be
   */
  value?: string | number;
  /**
   * label of the input field
   */
  label?: string | undefined;
  /**
   * name & id of the input field
   */
  sublabel?: string | undefined;
  /**
   * hint of the input field
   */
  hint?: string | undefined;
  /**
   * additional text under the label
   */
  name: string;
  /**
   * type of the input field
   */
  type?: string;
  /**
   * toggle for edit functionality - input is disabled when edit is set to false
   */
  edit?: boolean;
  /**
   * custom styling on top of pre-built styling
   */

  customStyle?: string | undefined;
  textAreaStyle?: string | undefined;
  customStyleEdit?: string | undefined;
  placeHolder?: string | undefined;
  externalInputStatus?: string;
  buttonDisabled?: boolean;
  setButtonDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
  fieldValidator?: any;
  /**
   * function of onChange event handler in input field. This should be a predefined function that accepts an object in the format {name:value}
   */
  handleLocalEdits?: (_arg: { [key: string]: string }) => void;
  handleChange?: any;
  validationOn?: boolean;
  isSmall?: boolean;
  textarea?: boolean;
  required?: boolean;
  showEditComponent?: boolean;
  externalErrorMessage?: string;
  setIsInputValid?: any;
  size?: string;
  customInputPadding?: string;
  trailingAddOn?: string;
  setIsEnterPressed?: any;
  onSubmit?: any;
  id?: any;
  rows?: any;
  autoFocus?: boolean;
  customInputDivStyle?: any;
  setExternalErrorMessage?: any;
  setExternalInputStatus?: any;
  contentEditable?: boolean;
  flexGrow?: boolean;
  enterToSubmit?: boolean;
  handleFocus?: any;
  //handleBlur?: any;
  onBlurFunction?: any;
  autoComplete?: boolean;
  formCss?: string;
  min?: string | number;
  onInput?: any;
  maxLength?: number;
  isError?: boolean;
  icon?: string;
  dotColor?: string;
  pattern?: any;
  handleKeydown?: any;
  readOnly?: boolean;
  optional?: boolean;
  tooltipText?: string;
}

export default function DynamicInput({
  id,
  value,
  label,
  sublabel,
  hint = "",
  name,
  type,
  edit,
  customStyle,
  textAreaStyle,
  customStyleEdit,
  placeHolder,
  buttonDisabled,
  setButtonDisabled,
  handleLocalEdits,
  handleChange,
  validationOn,
  isSmall,
  textarea,
  required,
  showEditComponent,
  externalErrorMessage,
  externalInputStatus,
  fieldValidator,
  setIsInputValid,
  size,
  customInputPadding,
  trailingAddOn,
  onSubmit,
  rows,
  autoFocus,
  customInputDivStyle,
  setExternalErrorMessage,
  setExternalInputStatus,
  contentEditable,
  flexGrow,
  enterToSubmit,
  handleFocus,
  //handleBlur,
  onBlurFunction,
  autoComplete,
  isError = false,
  formCss = "",
  icon,
  dotColor,
  pattern,
  handleKeydown,
  readOnly = false,
  optional = false,
  tooltipText = "",
  ...props
}: DynamicInputProps) {
  const status_icon = useRef<any>();

  // const debouncedText = useDebounce(value, 750);
  let validateInputValue = () => {};

  // const [mounted, setMounted] = useState(false);
  const [status, setStatus] = useState(externalInputStatus || "");
  const [errorMessage, setErrorMessage] = useState(externalErrorMessage || "");
  const [trailingAddOnState] = useState(trailingAddOn || "");
  const [suggestedEmail, setSuggestedEmail] = useState("");

  useEffect(() => {
    setErrorMessage(externalErrorMessage || "");
  }, [externalErrorMessage]);

  useEffect(() => {
    if (errorMessage) {
      setStatus("failure");
    } else setStatus("success");
  }, [errorMessage]);

  useEffect(() => {
    if (externalInputStatus) setStatus(externalInputStatus);
  }, [externalInputStatus]);

  useEffect(() => {
    if (isError) setStatus("failure");
    else setStatus("success");
  }, [isError]);

  const placeholderState = "";

  let iconElement: JSX.Element;
  switch (icon) {
    case "search":
      iconElement = (
        <SearchIcon
          className={`absolute left-3 top-3 h-5 w-5 text-gray-400 ${
            status === "failure" && "text-red-900"
          }`}
          aria-hidden="true"
        />
      );
      break;
    case "dollar":
      iconElement = (
        <span
          className={`absolute left-3 top-2.5 h-6 w-6 text-gray-400 ${
            status === "failure" && "text-red-900"
          }`}
          aria-hidden="true"
        >
          $
        </span>
      );
      break;
    case "status-dot":
      iconElement = (
        <span
          style={{ backgroundColor: dotColor, left: "14px", top: "17px" }}
          className={`absolute rounded-full w-2 h-2`}
        ></span>
      );
      break;
  }

  function spellCheckEmailDomain(input: any) {
    const emailName = input.split("@")[0];
    const emailDomain = input.split("@")[1];

    const suggestionsList = [
      "gmail.com",
      "yahoo.com",
      "icloud.com",
      "hotmail.com",
      "outlook.com",
      "aol.com",
      "live.com",
    ];
    if (suggestionsList.includes(emailDomain)) return;
    const fuseOptions = {
      shouldSort: true,
      threshold: 0.3, // Adjust the threshold as needed for more or fewer suggestions
      keys: ["email"],
    };
    const fuse = new Fuse(
      suggestionsList.map((email) => ({ email })),
      fuseOptions
    );
    const results = fuse.search(emailDomain).map((result) => result.item.email);
    if (results?.length === 0) return;
    const suggestedResult = `${emailName}@${results[0]}`;
    setSuggestedEmail(suggestedResult);
  }

  switch (name) {
    case "email":
      // placeholderState = "example@email.com";
      validateInputValue = () => {
        let hasError = false;

        if (validator.isEmail(value?.toString().trim())) {
          spellCheckEmailDomain(value);
          setStatus("success");
        } else {
          hasError = true;
          setStatus("failure");
          setErrorMessage("Invalid email");
        }
        return hasError;
      };
      break;

    case "phoneNumber":
      // placeholderState = "(555) 555-5555";
      validateInputValue = () => {
        let hasError = false;
        if (validator.isMobilePhone(value, "en-US")) {
          setStatus("success");
        } else {
          hasError = true;
          setStatus("failure");
          setErrorMessage("Invalid phone number");
        }
        return hasError;
      };
      break;
    case "phone_number":
      // placeholderState = "(555) 555-5555";
      validateInputValue = () => {
        let hasError = false;
        if (validator.isMobilePhone(value?.toString().trim() || "", "en-US")) {
          setStatus("success");
        } else {
          hasError = true;
          setStatus("failure");
          setErrorMessage("Invalid phone number");
        }
        return hasError;
      };
      break;
    case "phone_number_or_email":
      // placeholderState = "(555) 555-5555";
      validateInputValue = () => {
        let hasError = false;
        if (
          validator.isMobilePhone(value?.toString().trim(), "en-US") ||
          validator.isEmail(value?.toString().trim())
        ) {
          setStatus("success");
        } else {
          hasError = true;
          setStatus("failure");
          setErrorMessage("Invalid phone number or email");
        }
        return hasError;
      };
      break;

    case "number":
      validateInputValue = () => {
        let hasError = false;
        // const numberRegEx = /\-?\d*\.?\d{1,2}/;
        if (validator.isInt(value)) {
          setStatus("success");
        } else {
          hasError = true;
          setStatus("failure");

          setErrorMessage("Invalid input. Please enter a number.");
        }
        return hasError;
      };
      break;

    case "zipcode":
      validateInputValue = () => {
        let hasError = false;
        if (validator.isPostalCode(value, "US")) {
          setStatus("success");
        } else {
          hasError = true;
          setStatus("failure");
          setErrorMessage("Invalid ZIP code");
        }
        return hasError;
      };
      break;
    case "zipCode":
      validateInputValue = () => {
        let hasError = false;
        if (validator.isPostalCode(value, "US")) {
          setStatus("success");
        } else {
          hasError = true;
          setStatus("failure");
          setErrorMessage("Invalid ZIP code");
        }
        return hasError;
      };
      break;
    case "verification-contact":
      validateInputValue = () => {
        let hasError = false;
        if (validator.isMobilePhone(value) || validator.isEmail(value))
          setStatus("success");
        else {
          hasError = true;
          setErrorMessage("Invalid input");
          setStatus("failure");
        }
        return hasError;
      };
      break;
    case "facebook":
      validateInputValue = () => {
        let hasError = false;
        if (validator.isURL(value)) {
          setStatus("success");
        }
        // if (validator.isURL(value)) setStatus('success')
        else if (value?.toString().length === 0) {
          {
            setStatus("neutral");
          }
        } else {
          hasError = true;
          setStatus("failure");
          setErrorMessage("Invalid URL");
        }
        return hasError;
      };
      break;
    case "review_link":
      validateInputValue = () => {
        let hasError = false;
        if (validator.isURL(value)) setStatus("success");
        else if (value?.toString().length === 0) setStatus("neutral");
        else {
          hasError = true;
          setStatus("failure");
          setErrorMessage("Invalid URL");
        }
        return hasError;
      };
      break;
    case "existing_website":
      validateInputValue = () => {
        let hasError = false;
        if (validator.isURL(value)) setStatus("success");
        else if (value?.toString().length === 0) setStatus("neutral");
        else {
          hasError = true;
          setStatus("failure");
          setErrorMessage("Invalid URL");
        }
        return hasError;
      };
      break;
    default:
      if (fieldValidator) {
        validateInputValue = () => {
          let hasError = false;
          const validationStatus = fieldValidator(value);
          if (validationStatus === 0) {
            if (name === "gallery_name") {
              setErrorMessage("You already have a gallery with this name.");
            }
            hasError = true;
            setStatus("failure");
          }
          return hasError;
        };
      } else {
        validateInputValue = () => {
          let hasError = false;
          if (!value?.toString().length) {
            hasError = true;
            setStatus("failure");
          } else setStatus("success");
          return hasError;
        };
      }
      break;
  }

  if (status === "failure") {
    status_icon.current = (
      <ExclamationCircleIcon className="h-6 w-6 text-red-500" />
    );
  } else if (status === "success") {
    status_icon.current = "";
  } else if (status === "loading") {
    status_icon.current = (
      <img className="mx-auto h-5 w-5" src="/spinner.gif" alt="loading image" />
    );
  }

  useEffect(() => {
    if (setIsInputValid) {
      if (status === "failure") {
        setIsInputValid(false);
      } else {
        setIsInputValid(true);
      }
    }
  }, [status, setIsInputValid]);

  function newType() {
    return type === "password" && edit ? "text" : type;
  }
  const isPhone = type === "tel";
  let cssStyle = {};

  const editStyle = () => {
    if (edit) {
      if (customStyleEdit) {
        return customStyleEdit;
      } else {
        let paddingLeft;
        switch (icon) {
          case "dollar":
            paddingLeft = "32px";
            break;
          case "status-dot":
            paddingLeft = "29px";
            break;
          default:
            paddingLeft = "45px";
        }
        cssStyle = {
          paddingLeft: icon ? paddingLeft : "13px",
          paddingRight: "13px",
          paddingTop: "9px",
          paddingBottom: "9px",
        };
        return "bg-white my-1 w-full border border-gray-300 appearance-none rounded-md placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:ring-1 focus:border-blue-500 focus:z-10";
      }
    } else {
      return "text-gray-900 border-none";
    }
  };

  const getStyleFromSize = () => {
    switch (size) {
      case "lg":
        return {
          label: "text-h3-normal",
          sublabel: "text-body-normal",
          inputPadding: "py-4",
        };
      case "md":
        return {
          label: "",
          sublabel: "text-sm",
          inputPadding: "py-3",
        };
      default:
        return { label: "", sublabel: "", inputPadding: "" };
    }
  };

  function preventRefresh(e: any) {
    e.preventDefault();
    return;
  }

  function handleSetSuggestedEmail() {
    handleLocalEdits({
      [name]: suggestedEmail,
    });
    setSuggestedEmail("");
  }

  return (
    <form
      className={`${flexGrow && "flex flex-col"} w-full ${formCss}`}
      onSubmit={(e) => preventRefresh(e)}
    >
      {label && (
        <div className="flex items-center gap-x-2">
          <label
            htmlFor={name}
            className={`block text-sm-medium text-gray-700 text-left mb-1`}
          >
            {label}
            {optional && (
              <span className="ml-0.5 text-gray-500 text-sm-normal">
                {" "}
                (optional)
              </span>
            )}
          </label>
          {tooltipText && (
            <a
              data-tooltip-id="my-tooltip"
              data-tooltip-content={tooltipText}
              data-tooltip-place="top"
            >
              <InformationCircleIcon
                className="flex w-5 h-5 text-gray-500 cursor-pointer mb-1"
                style={{ strokeWidth: 1.5 }}
              />
            </a>
          )}
        </div>
      )}
      {sublabel && (
        <p
          className={`block text-xs-normal text-gray-500 mt-3 mb-2 ${
            getStyleFromSize().sublabel
          }`}
        >
          {sublabel}
        </p>
      )}
      <div className={customInputDivStyle ? customInputDivStyle : "relative"}>
        {textarea ? (
          <GenericTextArea
            name={name}
            data-cy={name}
            value={value}
            placeholder={placeHolder || placeholderState}
            handleLocalEdits={(e) => {
              setErrorMessage && setErrorMessage("");
              setExternalErrorMessage && setExternalErrorMessage("");
              handleLocalEdits(e);
            }}
            externalError={externalErrorMessage}
            handleChange={handleChange}
            isPhone={isPhone}
            editStyle={editStyle}
            getStyleFromSize={getStyleFromSize}
            customInputPadding={customInputPadding}
            customStyle={customStyle}
            textAreaStyle={textAreaStyle}
            edit={edit}
            validationOn={validationOn}
            rows={rows}
            size={size}
            autoFocus={autoFocus}
            onSubmit={onSubmit}
            required={required}
            enterToSubmit={enterToSubmit}
            {...props}
          />
        ) : (
          <div>
            <div className="relative">{iconElement && iconElement}</div>
            <input
              data-testid="dynamic-input"
              key={name}
              type={newType()}
              name={name}
              id={name}
              data-cy={name}
              placeholder={placeHolder || placeholderState}
              pattern={pattern}
              readOnly={readOnly}
              onFocus={handleFocus ? () => handleFocus() : () => {}}
              tabIndex={autoFocus === false ? -1 : 0}
              onKeyDown={
                handleKeydown
                  ? (e) => handleKeydown(e)
                  : (e) => {
                      let hasError = false;
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.stopPropagation();
                        e.preventDefault();
                        if (validationOn && value !== "") {
                          hasError = validateInputValue();
                        }
                        if (hasError === false) {
                          onSubmit && onSubmit();
                        }
                      }
                    }
              }
              onBlur={(e: any) => {
                if (validationOn && value !== "") {
                  validateInputValue();
                }
                if (required) {
                  if (!value) {
                    setStatus("failure");
                    setErrorMessage("Required");
                  } else setStatus("success");
                }
                // handleBlur && handleBlur();
                onBlurFunction && onBlurFunction(e);
              }}
              autoComplete={!autoComplete ? "off" : "on"}
              onChange={(e) => {
                setSuggestedEmail("");
                setErrorMessage("");
                setStatus("");
                if (setExternalErrorMessage && setExternalInputStatus) {
                  setExternalErrorMessage("");
                  setExternalInputStatus("");
                }
                handleLocalEdits({
                  [name]: `${
                    isPhone && /(.?\d){4,}/.test(e.target.value)
                      ? new AsYouType("US").input(e.target.value)
                      : e.target.value
                  }`,
                });
              }}
              className={`${editStyle()}  ${getStyleFromSize().inputPadding} ${
                customInputPadding && customInputPadding
              } ${
                customStyle || ""
              } text-body-normal placeholder-gray-500 placeholder-opacity-50 block w-full ${
                status === "failure" &&
                "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500"
              } ${
                trailingAddOnState &&
                `block w-full rounded-md border-gray-300 pl-3 pr-44 focus:border-blue-500 focus:ring-blue-500 text-sm-normal`
              }  
              rounded-md                   
                    bg-transparent
                `}
              style={cssStyle}
              disabled={!edit}
              value={value}
              required={required}
              autoFocus={autoFocus}
              {...props}
            />

            {!!trailingAddOnState && (
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 overflow-hidden">
                <span className="text-gray-400 text-sm-normal bg-white z-0 overflow-hidden">
                  {trailingAddOn}
                </span>
              </div>
            )}
            {status && (
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                {status_icon.current}
              </div>
            )}
          </div>
        )}
      </div>
      <div className="text-gray-500 text-sm-normal text-left">{hint}</div>
      <SuggestedEmail
        name={name}
        suggestedEmail={suggestedEmail}
        handleSetSuggestedEmail={handleSetSuggestedEmail}
      />

      {(errorMessage || externalErrorMessage) && (
        <Alert
          alertMessage={errorMessage || externalErrorMessage || ""}
          customStyle="text-left"
        />
      )}
    </form>
  );
}
